.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: #fafafafa; */
}

.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: #3bb19b;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}


@media (max-width: 768px){
  .container {
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: #fafafafa; */
  }

}

@media (min-width: 768px) {
  .container {
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: #fafafafa; */
  }
}