/* src/print-styles.css
.print-page {
    page-break-before: always;
  }
  
  .print-table {
    width: 100%;
  }
  
  .print-map {
    width: 100%;
    height: 300px;
    page-break-after: always;
  }
  
  @media print {
    h1 {
      page-break-before: always;
    }
  
    .print-table {
      width: 100%;
    }
  
    .print-map {
      width: 100%;
      height: 400px;
      page-break-after: always;
    }
  }
   */

   .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }