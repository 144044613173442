@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;900&display=swap");

/* # 1 - Dashboard Navbar & Sidebar */
/* # 2 - Dashboard home page */
/* # 3 - Backlink Table  */
/* # 4 - Add Links page */
/* # 5 - Detail Page  */

/* =========== Dashboard Navbar & Sidebar =========== */

.navbar {
  width: 100%;
  position: sticky;
  top: 0px;
  left: 0px;
  background-color: white;
  margin: 0px;
  padding: 5px;
  z-index: 9;
}

/* thi sis my comment*/
.mobile-nav-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: 0px;
  margin: 0px;
}

.sidebar-inner {
  position: relative;
  overflow: hidden;
  padding: 10px 4px;
}

.logoimg {
  width: 185px;
  height: 51px;
  margin-left: 20px;
}

.users-name {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.usera-name-bold {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.top-drop-menu-btn {
  border: none;
  background: none;
  font-weight: 500;
  font-family: Poppins;
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-item {
  padding: 2px 7px;
}

.chnage-pw {
  width: 450px;
  margin: auto;
  margin-top: 100px;
}

.feedback-pagee {
  max-width: 650px;
  min-width: auto;
  margin: auto;
  margin-top: 100px;
}

.login-check-box label {
  color: var(--Black, #2f2f2f);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135.5%;
  /* 21.68px */
}

.sidebar .nav-item.active>.nav-link {
  color: #ff9219 !important;
}

.sidebar .nav-item.active>.nav-link {
  background-color: none;
  border: none;
}

.sidebar .nav-link .sidebar-icon {
  margin-right: 0.7rem;
}

@media (min-width: 768px) {
  .sidebar {
    width: 54px;
    max-width: 83% !important;
    transition: 0.5s;
  }

  .sidebar:hover {
    width: 250px;
    max-width: 100%;
  }

  .sidebar:hover+.content {
    margin-left: 250px;
  }

  .content {
    margin-left: 57px;
  }

  .navbar-theme-primary:not(.headroom) {
    background-color: white;
    height: 74px;
  }
}

@media (max-width: 768px) {
  .navbar-theme-primary:not(.headroom) {
    background-color: white;
    height: 74px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 99;
  }

  .navbar-toggler {
    padding: 0.4rem 0.6rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: black;
    border: 0.0625rem solid transparent;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease-in-out;
  }

  .navbar-brand img {
    height: 40px;
  }

  .navbar {
    background-color: #262b40;
    height: 57px;
  }

  .logoimg {
    display: none;
  }

  .second-nav {
    padding-top: 7px;
  }

  .users-name {
    color: black;
  }

  .usera-name-bold {
    color: black;
  }
}

/* =========== Dashboard home page============= */

.main-dashboard {
  padding: 20px 20px;
  padding-bottom: 50px;
}

/* =========== Backlink Table ============= */

.top-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* ============Mobile-View============ */
.top-button-mobile {
  display: none;
}

.top-button-detail-mob {
  display: none;
}

.m-backlink-button1 {
  display: none;
}

.back-hints-mobile {
  display: none;
}

.hints-box-mob {
  display: none;
}

.btn-icon {
  color: white;
  font-weight: 300 !important;
  margin-right: 10px;
  font-size: 19px;
}

.img-icon {
  width: 19px;
  height: 18px;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 10px;
}

.img-icon2 {
  margin-left: 5px;
}

.backlink-button1 {
  width: auto;
  height: 44px;
  background-color: #ff9219;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-right: 9px;
  padding: 0px 15px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.backlink-button1-mob {
  height: 44px;
  background-color: #ff9219;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-right: 9px;
  padding: 0px 15px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.mbil-btn1 {
  width: 85%;
  height: 75px;
  border: none;
  color: #fff;
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backlink-button2 {
  width: auto;
  height: 44px;
  background-color: #2bb269;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* margin-right: 9px; */
  padding: 0px 15px;
  display: flex;
  align-items: center;
}

.backlink-button2-mob {
  height: 44px;
  background-color: #2bb269;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* margin-right: 9px; */
  padding: 0px 15px;
  /* display: flex; */
  align-items: center;
}

.backlink-button2add {
  margin-right: 9px;
}

.top-btn-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.top-btn-text {
  font-size: 14px;
  width: 80%;
  text-align: center;
  line-height: 15px;
  margin-top: 10px;
  color: black;
  font-weight: 700;
}

.backlink-button6 {
  width: 40px;
  height: 44px;
  background-color: #2bb269;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-right: 0px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
}

.backlink-button3 {
  width: auto;
  height: 44px;
  background-color: #1118b9;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-right: 9px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
}

.backlink-button3-mob {
  width: auto;
  /* height: 44px; */
  background-color: #1118b9;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-right: 9px;
  /* padding: 0px 15px; */
  display: flex;
  align-items: center;
}

.mobile-top-btn-box {
  display: flex;
  align-items: center;
  justify-content: right;
}

/* .mobile-btn-verify {
  display: flex;
  background-color: #00ba29;
  color: white;
  border: none;
  padding: 2px 4px;
  border-radius: 40px;
  margin-right: 15px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-weight: 400;
} */
.mobile-btn-verify {
  display: flex;
  background-color: #00ba29;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 40px;
  margin-right: 15px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-weight: 400;
}

.mobile-btn-verify.not-verified {
  background-color: #ff0000;
  display: flex;
  /* background-color: #00ba29; */
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 40px;
  margin-right: 15px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-weight: 400;
}

.mobile-icon-size {
  margin-right: 5px;
  font-size: 17px;
}

.custome-card-title {
  font-size: 18px;
  font-weight: 800;
  color: #1118b9;
}


.mobile-icon-mob {
  margin-right: 5px;
  font-size: 12px;
}

.mobile-div-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mobile-btn-links {
  font-size: 12px;
  border-radius: 50px;
  color: black;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.mobile-number {
  font-size: 9px;
  color: white;
  padding: 0px;
  border-radius: 100px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mob-des-box2 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.mob-description-box .mob-des-box2 h5 {
  font-weight: 800;
  color: #909090;
  font-size: 18px;
}

.mob-description-box .mob-des-box2 h6 {
  margin-left: 12px;
  font-size: 18px;
  font-size: 18px;
}

.mob-des-box3 {
  margin-top: 10px;
}

.mob-description-box .mob-des-box3 h5 {
  font-weight: 800;
  color: #ff9219;
  font-size: 18px;
}

.mob-description-box .mob-des-box3 h6 {
  font-size: 18px;
  font-size: 18px;
}

.mob-des-box4 {
  margin-top: 10px;
}

.mob-description-box .mob-des-box4 h5 {
  font-weight: 800;
  color: red;
  font-size: 18px;
}

.mob-description-box .mob-des-box4 h6 {
  font-size: 18px;
}

.costum-toggel {
  background: none;
  color: black;
  border: none;
  padding: 0px;
  margin: 0px;
}

.costum-toggel:hover {
  background: none !important;
  color: black;
  border: none;
  padding: 0px;
  margin: 0px;
}

.backlink-button4 {
  width: 44px;
  height: 44px;
  background-color: #ff9219;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-right: 9px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

.back-hints {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.back-hints2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.check-box {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 42%;
}

.check-box input {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  margin-right: 5px;
}

.error-box {
  background-color: red;
  color: white;
  padding: 3px 7px;
  margin-bottom: 7px;
  font-weight: 700;
  font-size: 14px;
}

.check-box label {
  color: #333;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.hintss {
  color: #666;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 19.5px */
}

.hints {
  color: #333;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 19.5px */
}

.hints2 {
  color: #333;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-left: 25px;
  /* 19.5px */
}

.view-tooltip-btn {
  background: none;
  margin: 0px;
  padding: 0px;
  border: none;
  line-height: 0;
  box-shadow: none;
  vertical-align: middle;
}

.view-tooltip-btn:hover {
  background: none;
  margin: 0px;
  padding: 0px;
  border: none;
  line-height: 0;
  box-shadow: none;
  vertical-align: middle;
}

.view-tooltip-btn:focus {
  background: none;
  margin: 0px;
  padding: 0px;
  border: none;
  line-height: 0;
  box-shadow: none;
  vertical-align: middle;
}

.tooltip-btn {
  background: none;
  margin-left: 5px;
  margin-right: 0px;
  padding: 0px;
  border: none;
  line-height: 0;
  box-shadow: none;
  width: 14px;
  vertical-align: middle;
}

.tooltip-btn:hover {
  background: none;
  margin-left: 5px;
  padding: 0px;
  border: none;
  line-height: 0;
  box-shadow: none;
  width: 14px;
  vertical-align: middle;
}

.tooltip-btn:focus {
  background: none;
  margin-left: 5px;
  padding: 0px;
  border: none;
  line-height: 0;
  box-shadow: none;
  width: 14px;
  vertical-align: middle;
}

.custom-tooltip {
  width: 250px;
}

.error-popup {
  display: none;
  position: absolute;
  margin-top: -75px;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  padding: 5px 10px;
  border-radius: 10px;
  --bs-popover-zindex: 1060;
  --bs-popover-max-width: 250px;
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  /* height: 100px;
  max-height: 200px;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.error-popup::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: calc(50% - 6px);
  border-width: 9px 9px 0 9px;
  border-style: solid;
  border-color: white transparent transparent;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.pop-btn:hover+.error-popup {
  display: block;
}

.error-popup:hover {
  display: block;
}

.error-popup2 {
  display: none;
  position: absolute;
  margin-top: -95px;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  padding: 5px 10px;
  border-radius: 10px;
  --bs-popover-zindex: 1060;
  --bs-popover-max-width: 250px;
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  /* height: 100px;
  max-height: 200px;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.error-popup2::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: calc(50% - 6px);
  border-width: 9px 9px 0 9px;
  border-style: solid;
  border-color: white transparent transparent;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.pop-btn:hover+.error-popup2 {
  display: block;
}

.error-popup2:hover {
  display: block;
}

.css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
  margin-left: 2px !important;
}

.table-tittle-search-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
  border-radius: 0px 0px 10px 10px;
}

.search-form {
  margin-right: 10px;
  position: relative;
  margin-bottom: -20px;
}

.search-form input {
  display: flex;
  width: 310px;
  height: 36px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-shrink: 0;
  padding-right: 40px;
  margin-left: 5px;
}

.search-form img {
  position: relative;
  top: -30px;
  left: 280px;
}

.tables-section {
  border-radius: 10px;
  border: 1px solid var(--Border, #e6edff);
  background: var(--White, #fff);
  margin-bottom: 50px;
}

.table thead th {
  font-weight: normal;
  text-transform: none;
  color: var(--Primary, #000);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  background-color: #f5f8fb;
  /* 18px */
}

.css-vtdehq-MuiTableCell-root {
  color: var(--Primary, #000);
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 2.8rem;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.list-cell-table {
  color: var(--Primary, #000);
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 2.8rem;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pagination-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
}

.cell-detail-page {
  font-family: Poppins !important;
  font-weight: 500 !important;
}

.pagination-no {
  background-color: white;
  color: black !important;
  border: none;
  border-radius: 10px !important;
  font-size: 15px !important;
  padding: 4px 4px !important;
  margin: 7px !important;
}

.pagination-no.pegi-selected {
  background-color: #262b40 !important;
  color: white !important;
  border: 1px solid gray;
  border-radius: 7px !important;
  font-size: 15px !important;
  padding: 5px 10px !important;
  margin: 10px !important;
}

.pegi-table-no {
  color: inherit;
  /* Use the same color as the parent */
}

.tables-section th {
  color: var(--Primary, #000);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 120%;
  align-items: center;
  background-color: #f5f8fb;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tables-section td {
  padding: 13px;
}

.table-tittle {
  color: var(--Primary, #000);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 10px;
}

.table-row {
  color: var(--Primary, #000);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 19.5px */
}

.card .table td,
.card .table th {
  padding-left: 0px;
  padding-right: 0px;
}

.card {
  border-radius: 5px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  padding: 20px 8px;
  margin: 7px;
}

.table-icon {
  width: 18px;
}

.view-icon {
  width: 20px;
  height: 20px;
}

.pagination {
  justify-content: center;
}

.refrence-model-main {
  position: relative;
}

.ref-btn-model {
  position: relative;
  top: 30px;
  right: -524px;
  margin-top: -39px;
}

.add-link-screen {
  width: 100%;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.add-link-screen img {
  width: 17%;
  height: auto;
}

.add-link-screen-tittle {
  color: #ff9219;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 135.5%;
  /* 62.33px */
}

.add-link-screen-sub-tittle {
  color: var(--Black, #2f2f2f);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135.5%;
  width: 35%;
}

.dashboard-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808080;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 10px;
  background-color: #f9f9fa;
  z-index: 99;
}

.dashboard-footer span {
  margin: 0px 10px;
}

.footer-content {
  text-align: center;
}

.modal {
  position: fixed;
  top: 35px;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 650px;
  max-height: 90vh;
  padding: 10px;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(46, 54, 80, 0.2);
  border-radius: 20px;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  outline: 0;
  scrollbar-width: none;
  scrollbar-color: #aaa #fff;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
}

.model-top-sec {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.model-header-set {
  padding: 3px 20px !important;
}

.model-tittle-1 {
  color: #000;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 39px */
}

.model-form-lable {
  color: var(--Black, #2f2f2f);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135.5%;
  /* 21.68px */
}

.model-drop-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.model-check-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1024px) {
  .hints-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .hints2 {
    margin-left: 10px;
    margin-top: 5px;
  }

  /* .check-box {
    margin: 10px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  } */
}

/* ===================  Add Links page ============== */

.add-link-box {
  padding: 15px 0px;
}

.add-link-box h2 {
  color: #333;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 135.5%;
}

.rediobtn-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .form-check {
  margin-right: 40px;
} */

.form-check-input:active {
  background-color: #ff9219;
  border: 1px solid #ff9219;
}

.form-check-input:focus {
  background-color: #ff9219;
  border: 1px solid #ff9219;
}

.form-check-label {
  color: var(--Black, #2f2f2f);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 135.5%;
  /* 29.81px */
}

.form-group {
  margin-top: 0px;
  margin-bottom: 0px;
}

.add-from-link {
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 20px 15px;
  border-radius: 8px;
  background: #ededed;
}

.add-from-link-mobile {
  margin-top: 0px;
  margin-bottom: 20px;
}

.add-from-link-mobile-main {
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 20px 15px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.close-set {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  display: inline-flex;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.upload-box {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 30px;
}

.upload-tittle-top {
  color: #ff9219;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 135.5%;
  padding: 0px;
  margin: 0px;
}

.upload-tittle-top-mob {
  color: #ff9219;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 135.5%;
  padding: 0px;
  margin: 0px;
}

.upload-tittle-top2 {
  color: #333;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 135.5%;
  padding: 0px;
  margin: 0px;
}

.upload-tittle-top2-mob {
  color: #333;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 135.5%;
  padding: 0px;
  margin: 0px;
}

.file-input {
  position: relative;
}

.file-input label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 373px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px dashed #ccc;
  background: #fff;
}

.file-input .add-card-mob {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px dashed #ccc;
  background: #fff;
}

.file-input input {
  border: none;
  background: transparent;
  box-shadow: none;
  position: absolute;
  left: 42%;
  top: 68%;
  margin-bottom: 0px;
  width: 30%;
  font-size: 12px;
}

.file-input input[type="file"]::-webkit-file-upload-button {
  border: none;
  background: transparent;
  color: white;
  display: none;
}

.file-input input[type="checkbox"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.add-file-type {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 135.5%;
  margin-top: 15px;
  margin: 0px;
  padding: 0px;
}

.add-file-type-mob {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 135.5%;
  margin-top: 15px;
  margin: 0px;
  padding: 0px;
}

.add-file-type2 {
  color: #888;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135.5%;
  margin: 0px;
  padding: 0px;
}

.no-error {
  display: flex;
  width: 100%;
  height: 42px;
  padding: 12px 40px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(45, 255, 116, 0.2);
  color: #127f44;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 15px;
}

.yes-error {
  display: flex;
  width: 100%;
  height: 42px;
  padding: 12px 40px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(255, 45, 45, 0.2);
  color: #fd0000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;
}

.dropdown-addref-sec1 {
  position: relative;
  width: 88%;
  z-index: 99;
  border: none;
  margin-left: 3px;
}

.dropdown-addref-sec {
  position: relative;
  width: 100%;
  top: -44px;
  z-index: 9;
  height: 46px;
  padding-right: 37px;
}


.dropdown-addref-sec2 {
  position: relative;
  width: 90%;
  z-index: 99;
  border: none;
  margin-left: 3px;
}

.dropdown-addref-sec3 {
  position: relative;
  width: 100%;
  top: -44px;
  z-index: 9;
  height: 46px;
}

/* =========== Detail Page ============= */

.detail-tittle {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.detail-tittle p {
  color: var(--Primary, #000);
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 0px;
  padding: 0px;
}

.custom-accordion {
  width: 100%;
}

.accordion-item {
  margin-bottom: 8px;
  border-radius: 10px;
  border: 1px solid var(--Border, #e6edff);
  background: var(--White, #fff);
}

.deatil-tittle {
  margin-left: 5px;
}

.detail-btn-edit {
  background: none;
  color: #1118b9;
  border: none;
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  box-shadow: none;
}

.detail-btn-edit:hover {
  padding: 2px 10px;
}

.nodata-found {
  font-family: Poppins;
  font-weight: 500;
  margin-left: 20px;
}

.accordion-title {
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ff9219;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
}

/* .accordion-title:hover {
  background-color: #dddddd80;
} */

.accordion-icon {
  width: 20px;
  height: 20px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.accordion-icon.open {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 10px;
  border-radius: 10px;
  background-color: #fbfbfb;
}

.detail-container {
  display: flex;
  align-items: center;
}

.inputsize {
  width: 100%;
  margin-bottom: 10px;
}

.detail-tittle {
  color: var(--Primary, #000);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-left: 6px;
}

.form-group {
  /* margin-right: 10px; */
}

.edit-buttons {
  display: flex;
}

.deatil-sub {
  color: var(--Primary, #000);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.badges {
  width: 110px;
  height: 26px;
  padding: 4px 16px;
  margin-left: 10px;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(45, 255, 116, 0.2);
  color: #127f44;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.greenext {
  margin-left: 10px;
  color: #127f44;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.deatil-img-icon {
  width: 13px;
  height: 13px;
  flex-shrink: 0;
  margin-left: 5px;
}

.deatil-img-icon2 {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-left: 5px;
}

.detail-collapse {
  padding: 2px 17px;
}

.collapse-header {
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
}

.collapse-icon {
  width: 17px !important;
  height: 17px !important;
  background-color: #1118b9;
  color: white;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
}

.collapse-img {
  width: 21px;
  height: 21px;
  margin-left: 8px;
  margin-right: 8px;
}

.collapse-tittle {
  color: #222;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.collapse-content {
  padding-top: 10px;
}

.collapse-card {
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 17px;
}

.collapse-card-tittle {
  color: #1118b9;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding: 0px;
  margin: 0px;
}

.collapse-card-sub-tittle {
  color: #ff9219;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-decoration-line: underline;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.collapse-sub-sec {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.collapse-card-sub-tittle2 {
  color: #2bb269;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-decoration-line: underline;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.collapse-card-sub-tittle ul {
  padding: 0px;
  margin: 0px;
}

.link-col {
  color: #1118b9;
  text-align: center;
}

.table td,
.table th {
  padding-left: 15px;
}

.edit-detai-btn {
  background: none;
  color: #1118b9;
  border: none;
  box-shadow: none;
  padding: 3px 7px;
  margin-right: -5px;
  border-radius: 4px;
}

.edit-detai-btn2 {
  background: none;
  border: none;
  box-shadow: none;
  padding: 3px 7px;
  margin-right: 0px;
  border-radius: 4px;
}

.edit-detai-btn2:hover {
  background: white;
}

.edit-detai-btn1 {
  background: #1118b9;
  border: none;
  box-shadow: none;
  padding: 3px 8px;
  margin: 0px;
  margin-left: 10px;
  border-radius: 4px;
}

.detail-btn-edt {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
}

.detail-btn-edt img {
  width: 23px;
}

.detail-btn-edt1 {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
  padding-bottom: 2px;
}

.inputbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-btn-edt1 img {
  width: 22px;
}

.note-model-th {
  margin-top: 50px;
}

.check-input label {
  font-size: 15px;
}

.alert {
  padding: 2px 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffebcc;
  margin-top: -10px;
  margin-bottom: 11px;
}

.arlt-main-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alrt-btn {
  padding: 5px 5px;
  font-size: 12px;
}

.alrt-inf0-icon {
  background-color: #fd7900;
  border-radius: 50px;
  padding: 3px 9px;
  font-size: 10px;
  margin-right: 5px;
  font-weight: 100;
}

.alrt-inf0-text {
  font-size: 12px;
  font-weight: 700;
  color: #fd7900;
  margin-left: 5px;
}

.modal-body {
  overflow-y: scroll;
  outline: 0;
  scrollbar-width: none;
  scrollbar-color: #aaa #fff;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
}

.modal-dialog {
  margin-top: 70px;
}

.edit-model-btn {
  display: flex;
  justify-content: space-between;
}

.model-body-delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
}

.model-delete-img {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.model-btn-flex {
  justify-content: space-between;
}

.view-tooltip-btn::selection {
  background-color: none !important;
}

.view-tooltip-btn:hover {
  background-color: none !important;
}

.inner-select-css {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 30px;
  padding-left: 5px;
  height: 30px;
  border-radius: 5px;
}

/* responsive */

@media (max-width: 998px) {
  .users-name {
    color: black;
  }

  .usera-name-bold {
    color: black;
  }

  .container {
    height: 83vh !important;
  }

  .login-text1 h2 {
    font-size: 26px !important;
  }

  .login-text1 p {
    font-size: 24px !important;
  }

  .login-img-main {
    display: none !important;
  }

  .login-logo {
    width: 308px !important;
    height: auto;
    margin-bottom: 60px !important;
  }

  .sidebar-inner {
    position: relative;
    overflow: hidden;
    padding: 0px 0px;
  }

  .custom-accordion {
    margin-top: 25px;
  }

  .back-hints-mobile {
    display: flex;
  }

  .back-hints {
    display: none;
  }

  .main-dashboard {
    padding: 19px 10px;
    padding-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .alert {
    margin-top: -10px;
    margin-bottom: 4px;
  }

  .inputbox .form-group {
    width: 78% !important;
  }

  .detail-container1 input {
    width: 100%;
  }

  .border-0 .detail-container {
    width: 100%;
    min-width: 290px;
  }

  .detail-tittle p {
    font-size: 18px;
    width: 100%;
    overflow: scroll;
  }

  .model-check-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .dropdown-addref-sec2 {
    width: 80%;
  }

  .accordion-item {
    /* overflow: scroll; */
  }

  .accordion-content {
    overflow: scroll;
  }

  .deatil-main-box {
    margin-top: 20px;
  }

  .file-input input {
    border: none;
    background: transparent;
    box-shadow: none;
    position: absolute;
    left: 37%;
    top: 74%;
    margin-bottom: 0px;
    width: 30%;
    font-size: 12px;
  }

  /* .model-add-btn {
    margin-top: 10px;
  } */

  .model-top-sec {
    flex-direction: column;
  }

  .modal-header .btn-close {
    margin-bottom: 36px;
  }

  .model-tittle-1 {
    font-size: 22px;
    margin-bottom: 5px;
  }

  /* .model-btn-flex {
    flex-direction: column;
  } */

  .edit-model-btn {
    display: flex;
    flex-direction: column;
  }

  .navbar-expand {
    display: none;
  }

  /* .dashboard-footer {
    position: relative;
    bottom: 0px;
    left: 0px;
  } */

  .top-button {
    flex-direction: column;
    display: none;
  }

  .top-button-mobile {
    display: flex;
    justify-content: space-between;
  }

  .top-button-detail-mob {
    display: flex;
    justify-content: space-between;
  }

  .m-backlink-button1 {
    display: none;
  }

  .btn-icon {
    color: white;
    font-size: 19px;
  }

  .img-icon {
    height: 18px;
  }

  /* .backlink-button1 {
    width: 100%;
    margin-right: 12px;
    margin-bottom: 10px;
    height: 70px;
  } */

  .backlink-button3 {
    width: 100%;
    margin: 0px 10px;
    margin-bottom: 10px;
    height: 70px;
  }

  .backlink-button3-mob {
    /* width: 100%; */
    margin: 0px 10px;
    margin-bottom: 10px;
    height: 50px;
  }

  .btn-icon-mob {
    color: white;
    font-weight: 300 !important;
    margin-right: 4px;
    font-size: 19px;
  }

  .img-icon-mob {
    width: 19px;
    flex-shrink: 0;
    margin-right: 4px;
  }

  .hints-box {
    display: none;
  }

  .hints-box-mob {
    display: block;
    padding: 10px 0px;
  }

  .hints-mob {
    color: #333;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  .hints2-mob {
    color: #333;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-left: 15px;
  }

  .pagination-box {
    display: none;
  }

  .link-btn-wt {
    width: 100%;
    margin-bottom: 10px;
  }

  /* .backlink-button2 {
    width: 100%;
  } */

  .backlink-button2add {
    margin-right: 0px;
    margin-bottom: 10px;
    height: 70px;
  }

  .check-box {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: -14px;
    /* margin-right: 3px; */
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .tables-section {
    margin-top: 15px;
  }

  .check-box label {
    color: #333;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hints {
    color: #333;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  .hints2 {
    color: #333;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-left: 22px;
  }

  .ref-btn-model {
    position: relative;
    top: 39px;
    right: -524px;
    margin-top: -39px;
  }

  .add-link-screen img {
    width: 75%;
    height: auto;
  }

  .add-link-screen-sub-tittle {
    width: 90%;
  }

  .modal-content {
    width: 100%;
    max-height: 92vh;
  }

  .modal-dialog {
    margin-top: 40px;
  }

  .login-text1 h2 {
    font-size: 26px;
  }

  .login-text1 p {
    font-size: 24px;
  }

  /* .accordion-title {
    padding: 10px;
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  } */
  .accordion-item {
    margin-bottom: 10px;

    overflow: hidden;
  }

  .accordion-title {
    background-color: #fff;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: black;
  }

  .accordion-title:hover {
    background-color: #ff9219;
    color: #fff;
    border-radius: 10px 10px 0px;
  }

  .accordion-title .accordion-icon {
    margin-right: 10px;
    transition: transform 0.3s;
  }

  .accordion-title .accordion-icon.open {
    transform: rotate(180deg);
  }

  .accordion-content {
    padding: 10px;
    background-color: #fff;
  }

  .deatil-tittle {
    font-weight: 600;
    font-size: 14px;
  }

  .chnage-pw {
    width: auto;
  }
}

@media (max-width: 568px) {
  .table-tittle-search-box {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .ref-btn-model {
    position: relative;
    top: 39px;
    right: -292px;
    margin-top: -39px;
  }

  .detail-btn-edt img {
    width: 53px;
  }

  .hints-box-mob {
    display: block;
    padding: 10px 0px;
    width: 95%;
  }

  .inner-select-css {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 30px;
    padding-left: 5px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px !important;
    margin: 1.75rem auto;
  }

  /* .ref-btn-model {
    position: relative;
    top: 39px;
    right: -270px;
    margin-top: -56px;
  } */
}

@media (max-width: 440px) {
  .ref-btn-model {
    position: absolute;
    top: 46px;
    right: 0px;
    margin-top: -56px;
  }
}

@media (max-width: 398px) {
  .ref-btn-model {
    position: absolute;
    top: 46px;
    right: 0px;
    margin-top: -56px;
  }

  .mobile-btn-links {
    font-size: 9px;
    border-radius: 50px;
    color: black;
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .modal-dialog {
    margin-top: 0px;
  }

  .hints-mob,
  .hints2-mob {
    font-size: 8px;
  }

  .hints {
    font-size: 8px;
  }
}

/* table css */

/* new test 2 */
#custom-table {
  width: 100%;
  border-collapse: collapse;
  /* Ensure table borders collapse properly */
}

#custom-table thead {
  background-color: #000;
  color: #fafafa;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #000;
  color: #fafafa;
}

/* @media (max-width: 768px) { */
#custom-table thead {
  position: relative;
  /* Reset position for all headers */
}

.sticky-header {
  position: sticky;
  left: 0;
  /* Ensure sticky header is fixed on the left */
  z-index: 2;
  /* Ensure sticky header is above other headers */
  left: 0;
}

/* } */