@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap');

.log-main {
    background: #F4F4F4;
}

.login-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-logo {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.login-img-main {
    width: 95%;
    height: auto;
}

.login-text1 p {
    color: var(--Black, #2F2F2F);
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0px;
}

.login-text1 h2 {
    color: #FF9219;
    font-family: Poppins;
    font-size: 33px;
    font-weight: 900;
}

.input-grp {
    border-radius: 8px;
    background: #ECECEC;
    padding-left: 20px;
}

.input-grp1 {
    border-radius: 8px;
    background: #ECECEC;
}

.remeberme {
    display: flex;
    justify-content: space-between;

}

.forgot-password-link Link {
    color: #6358DC;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.5%;
}

.login-btn {
    width: 100%;
    border-radius: 8px;
    background: #FF9219;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.5%;
    border: none;
    /* 29.81px */
}

.register-link {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.5%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    /* 21.68px */
}

.register-link-link {
    color: #6358DC;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.5%;
}

.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.or-or {
    color: var(--Black, #2F2F2F);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.5%;
    /* 21.68px */
}

.or-line {
    height: 2px;
    width: 45%;
    background: #BFBFBF;
}

.g-btn {
    margin-top: 10px;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.11);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.g-btn img {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-right: 15px;
}

.g-btn p {
    color: var(--Black, #2F2F2F);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.5%;
    margin: 0px;
    /* 21.68px */
}


